import * as React from 'react';
import { isArray } from '../helpers/javascript';
import { cleanupUserData, getGoogleSheetToken } from '../services/security.service';
import { manageCart } from '../services/stripe.services';
import { useUser } from '@auth0/nextjs-auth0/client';
import { gtmFireEvent } from '~/helpers/gtm';
import getWoxoLocationFromPath from './helpers/signUpHelper';
import { useTeamStore } from '~/store/team-store/use-team.store';
import { unstable_batchedUpdates } from 'react-dom';

type AuthState = Omit<AuthContextValue, 'dispatch'>;
const initialState: AuthState = {
  notifications: { data: [], count: 0 }
};

type ActionType =
  | { type: 'UPDATE_SHEET_TOKEN' }
  | { type: 'CLEAR_NOTIFICATIONS' }
  | { type: 'PUSH_NOTIFICATIONS'; notifications: Notifications }
  | { type: 'REMOVE_NOTIFICATIONS'; notification: string }
  | { type: 'CHANGE_TEAM'; team: { id: string; brandings: any[] } }
  | { type: 'CHANGE_BRANDING'; branding: Object };

const authReducer = (state: AuthState, action: ActionType) => {
  switch (action.type) {
    case 'UPDATE_SHEET_TOKEN':
      return { ...state, token: getGoogleSheetToken() };
    case 'CLEAR_NOTIFICATIONS': {
      return { ...state, notifications: { data: [], count: 0 } };
    }
    case 'PUSH_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          data:
            state.notifications && isArray(state.notifications.data)
              ? state.notifications.data.concat(action.notifications.data)
              : action.notifications.data,
          count: action.notifications.count
        }
      };
    case 'REMOVE_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          data:
            state.notifications && isArray(state.notifications.data)
              ? state.notifications.data.filter((n) => n._id !== action.notification)
              : [],
          count: state.notifications.count === 0 ? 0 : state.notifications.count - 1
        }
      };
    case 'CHANGE_TEAM': {
      unstable_batchedUpdates(() => useTeamStore.getState().setTeam(action.team));
      return state;
    }
    case 'CHANGE_BRANDING': {
      unstable_batchedUpdates(() => useTeamStore.getState().setBranding(action.branding));
      return state;
    }
    default:
      throw new Error('Inexistent action');
  }
};

interface Notification {
  _id: string;
}

interface Notifications {
  data: Notification[];
  count: number;
}

interface AuthContextValue {
  notifications: Notifications;
  dispatch: React.Dispatch<ActionType>;
}

const AuthContext = React.createContext(initialState);

export function AuthProvider({
  children,
  stripeSuccess
}: React.PropsWithChildren<{ stripeSuccess?: string }>) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  const { user } = useUser();
  const isAuthenticated = user != null;

  const value = React.useMemo(
    () => ({
      notifications: state.notifications,
      dispatch
    }),
    [state.notifications]
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      if (stripeSuccess) {
        manageCart({ status: stripeSuccess === 'false' ? 'active' : 'inactive' });
      }
    }
  }, [isAuthenticated, stripeSuccess]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthentication() {
  const settings = React.useContext(AuthContext as unknown as React.Context<AuthContextValue>);
  const auth0 = useUser();
  const user = React.useMemo(() => {
    const user = auth0.user;
    if (user) {
      return {
        ...user,
        _id: user.sub?.split('|')[1],
        username: (user.username ||
          user['https://woxo.tech/username'] ||
          user.nickname?.replaceAll(' ', '_')) as string
      };
    }
    return undefined;
  }, [auth0.user]);
  const isAuthenticated = user != null;

  return {
    ...settings,
    ...auth0,
    user,
    isAuthenticated,
    showLoginWithRedirect: React.useCallback(
      (
        returnTo: string | null = null,
        screen_hint: string = 'login',
        user_audience: string = 'video'
      ) => {
        const stage = screen_hint === 'signup' ? getWoxoLocationFromPath() : '';
        if (window) localStorage.setItem('lastReferrer', window.location.pathname);
        const params = new URLSearchParams({ returnTo: returnTo || '', user_audience, stage });
        if (screen_hint === 'signup') {
          window.location.href = `/api/auth/signup?${params}`;
        } else {
          window.location.href = `/api/auth/login?${params}`;
        }
      },
      []
    ),
    showLogin: React.useCallback(() => {
      window.location.href = `/api/auth/login`;
    }, []),
    showSignUp: React.useCallback(() => {
      window.location.href = `/api/auth/signup`;
    }, []),
    logout: React.useCallback((returnTo?: string) => {
      cleanupUserData();

      gtmFireEvent({
        event: 'onSecurity',
        category: 'security',
        action: 'logout',
        label: 'user logout action'
      });

      gtmFireEvent({
        event: 'userLogout'
      });

      window.location.href = `/api/auth/logout?returnTo=${returnTo || window.location.origin}`;
    }, [])
  };
}
