import useSWRImmutable from 'swr/immutable';
import { appsumoPrices, prices } from '../services/stripe.services';

const useRegisterPlans = ({ category }: { category: 'appsumo' | 'widget' | 'video' }) => {
  const { data: plans } = useSWRImmutable(['plans', category], () => {
    if (category === 'appsumo') {
      return appsumoPrices();
    }
    return prices({ category });
  });

  return plans;
};

export default useRegisterPlans;
