import SvgIcon from '@mui/material/SvgIcon';
import { ComponentPropsWithoutRef } from 'react';

export const SentIcon = () => (
  <SvgIcon>
    <svg viewBox="0 0 334.5 334.5">
      <path
        d="M332.797,13.699c-1.489-1.306-3.608-1.609-5.404-0.776L2.893,163.695c-1.747,0.812-2.872,2.555-2.893,4.481
	s1.067,3.693,2.797,4.542l91.833,45.068c1.684,0.827,3.692,0.64,5.196-0.484l89.287-66.734l-70.094,72.1
	c-1,1.029-1.51,2.438-1.4,3.868l6.979,90.889c0.155,2.014,1.505,3.736,3.424,4.367c0.513,0.168,1.04,0.25,1.561,0.25
	c1.429,0,2.819-0.613,3.786-1.733l48.742-56.482l60.255,28.79c1.308,0.625,2.822,0.651,4.151,0.073
	c1.329-0.579,2.341-1.705,2.775-3.087L334.27,18.956C334.864,17.066,334.285,15.005,332.797,13.699z"
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  </SvgIcon>
);

export const WoxoIcon = () => (
  <SvgIcon viewBox="0 0 48 48" sx={{ marginRight: '12px' }}>
    <polygon fill="#ffd400" points="30.93 28 30.93 20 24 16 17.07 20 17.07 28 24 32 30.93 28" />
    <polygon fill="#ff0086" points="30.93 12 30.93 4 24 0 17.07 4 17.07 12 24 16 30.93 12" />
    <polygon fill="#7c00ff" points="17.07 20 17.07 12 10.14 8 3.22 12 3.22 20 10.14 24 17.07 20" />
    <polygon fill="#0086ff" points="17.07 36 17.07 28 10.14 24 3.22 28 3.22 36 10.14 40 17.07 36" />
    <polygon fill="#40bf00" points="30.93 44 30.93 36 24 32 17.07 36 17.07 44 24 48 30.93 44" />
    <polygon
      fill="#ff7f00"
      points="44.78 36 44.78 28 37.85 24 30.93 28 30.93 36 37.85 40 44.78 36"
    />
    <polygon fill="red" points="44.78 20 44.78 12 37.85 8 30.93 12 30.93 20 37.85 24 44.78 20" />
  </SvgIcon>
);

export const WoxoLargeIcon = ({ sx }: { sx: $TSFixMe }) => (
  <SvgIcon sx={sx} viewBox="0 0 185.3 59.8">
    <g>
      <path
        fill="#414042"
        d="M46.6,47.6H38l-4.8-18.7c-0.2-0.7-0.5-2-0.9-4.1c-0.4-2.1-0.7-3.5-0.7-4.2c-0.1,0.9-0.3,2.3-0.7,4.2 c-0.4,1.9-0.7,3.3-0.9,4.2L25,47.6h-8.6L7.3,11.9h7.4l4.6,19.5c0.8,3.6,1.4,6.7,1.7,9.3c0.1-0.9,0.3-2.4,0.7-4.3 c0.3-1.9,0.7-3.5,1-4.5l5.2-20h7.1l5.2,20c0.2,0.9,0.5,2.3,0.9,4.1c0.3,1.8,0.6,3.4,0.8,4.7c0.2-1.3,0.4-2.8,0.8-4.7 c0.4-1.9,0.7-3.4,1-4.6l4.5-19.5h7.4L46.6,47.6z"
      ></path>
      <path
        fill="#414042"
        d="M138.9,47.3h-8.6L122,33.9l-8.3,13.5h-8.1L117.4,29l-11.1-17.3h8.3l7.7,12.8l7.5-12.8h8.1l-11.2,17.7 L138.9,47.3z"
      ></path>
      <path
        fill="#414042"
        d="M177.2,29.5c0,5.9-1.5,10.4-4.4,13.6c-2.9,3.2-7.1,4.8-12.6,4.8c-5.5,0-9.7-1.6-12.6-4.8 c-2.9-3.2-4.4-7.7-4.4-13.7s1.5-10.5,4.4-13.6c2.9-3.1,7.1-4.7,12.6-4.7s9.7,1.6,12.6,4.7C175.8,19,177.2,23.6,177.2,29.5z M151.2,29.5c0,4,0.8,7,2.3,9c1.5,2,3.8,3,6.8,3c6,0,9-4,9-12c0-8-3-12-9-12c-3,0-5.3,1-6.8,3C152,22.5,151.2,25.5,151.2,29.5z"
      ></path>
      <g>
        <polygon
          fill="#FFD400"
          points="87.9,33 87.9,25.3 81.3,21.5 74.6,25.3 74.6,33 81.3,36.8 "
        ></polygon>
        <polygon
          fill="#FF0086"
          points="87.9,17.7 87.9,10 81.3,6.2 74.6,10 74.6,17.7 81.3,21.5 "
        ></polygon>
        <polygon
          fill="#7C00FF"
          points="74.6,25.3 74.6,17.7 68,13.8 61.4,17.7 61.4,25.3 68,29.2 "
        ></polygon>
        <polygon
          fill="#0086FF"
          points="74.6,40.7 74.6,33 68,29.2 61.4,33 61.4,40.7 68,44.5 "
        ></polygon>
        <polygon
          fill="#40BF00"
          points="87.9,48.3 87.9,40.7 81.3,36.8 74.6,40.7 74.6,48.3 81.3,52.1 "
        ></polygon>
        <polygon
          fill="#FF7F00"
          points="101.2,40.6 101.2,33 94.5,29.2 87.9,33 87.9,40.6 94.5,44.5 "
        ></polygon>
        <polygon
          fill="#FF0000"
          points="101.2,25.3 101.2,17.7 94.5,13.9 87.9,17.7 87.9,25.3 94.5,29.2 "
        ></polygon>
      </g>
    </g>
  </SvgIcon>
);

export const Crown = (props: ComponentPropsWithoutRef<typeof SvgIcon>) => (
  <SvgIcon {...props}>
    <path
      fill="#F2BD25"
      d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"
    />
  </SvgIcon>
);
